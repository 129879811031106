import React, { useState, useEffect } from 'react';
import { Button, Input, FormGroup, Label, Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import mammoth from 'mammoth';
import { v4 as uuidv4 } from 'uuid';
import sdk from 'api-sdk';
import { toast } from 'react-toastify';

const SigmaRuleParser = () => {
    const [scenarios, setScenarios] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [silverBullets, setSilverBullets] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    const [existingScenarios, setExistingScenarios] = useState([]);

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const [bulletsResponse, scenariosResponse] = await Promise.all([
                    sdk.silverBullets.get(),
                    sdk.scenario.get()
                ]);
                setSilverBullets(bulletsResponse);
                setExistingScenarios(scenariosResponse);
            } catch (error) {
                console.error('Initial data load error:', error);
                toast.error('Failed to load initial data');
            }
        };
        fetchInitialData();
    }, []);

    const extractScenarios = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const elements = Array.from(doc.body.children);
    
        const scenarios = [];
        let currentScenario = null;
        let currentSection = null;
    
        elements.forEach((element) => {
            const tagName = element.tagName.toLowerCase();
            const text = element.textContent.trim();
    
            // Detect new scenario (handle headers)
            if (tagName === 'h1') {
                if (currentScenario) scenarios.push(currentScenario);
                currentScenario = {
                    id: uuidv4(),
                    name: text.replace(/(# |\[•\])/g, '').trim(),
                    description: '',
                    attackFlowImage: null,
                    detectionRule: '',
                    selectedSilverBullet: null
                };
                
                currentSection = null;
                return;
            }
    
            if (!currentScenario) return;
    
            // Handle section headers
            if (tagName === 'p' ) {
                const sectionMatch = text.match(/^(Description|Attack Flow|Detection Rule)$/i);
                if (sectionMatch) {
                    currentSection = sectionMatch[1].toLowerCase().replace(' ', '');
                    return;
                }
            }
    
            // Handle content based on current section
            switch (currentSection) {
                case 'description':
                    if (tagName === 'p' && !text.match(/\[.*?\]/)) {
                        currentScenario.description += text + '\n';
                    }
                    break;
    
                case 'attackflow':
                    console.log('image');
                    
                    // Check for nested <img> within the current element
                    const imgs = element.querySelectorAll('img');
                    if (imgs.length > 0 && !currentScenario.attackFlowImage) {
                        currentScenario.attackFlowImage = imgs[0].getAttribute('src');
                    }
                    break;
    
                case 'detectionrule':
                    if (tagName === 'pre') {
                        currentScenario.detectionRule = element.textContent.trim();
                    } else if (tagName === 'p' && text.startsWith('title:')) {
                        currentScenario.detectionRule = element.textContent.trim();
                    }
                    break;
    
                default:
                    if (tagName === 'p' && text && !text.match(/\[.*?\]/)) {
                        currentScenario.description += text + '\n';
                    }
            }
        });
    
        if (currentScenario) scenarios.push(currentScenario);
    
        // Clean up descriptions
        return scenarios.map(scenario => ({
            ...scenario,
            description: scenario.description
                .replace(/\n+/g, '\n')
                .replace(/\[Description\]\s*/i, '')
                .trim(),
            detectionRule: scenario.detectionRule.trim()
        }));
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setIsLoading(true);
        setError('');
        setScenarios([]);

        try {
            const result = await mammoth.convertToHtml(
                { arrayBuffer: await file.arrayBuffer() },
                {
                    convertImage: mammoth.images.imgElement(image => {
                        return image.read('base64').then(content => ({
                            src: `data:${image.contentType};base64,${content}`,
                            contentType: image.contentType
                        }));
                    })
                }
            );

            const extractedScenarios = extractScenarios(result.value);
            setScenarios(extractedScenarios.map(scenario => ({
                ...scenario,
                selectedSilverBullet: silverBullets.find(b => b.name === scenario.name) || null
            })));
        } catch (err) {
            setError('Error parsing Sigma rule document');
            console.error('DOCX parsing error:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSilverBulletSelect = (scenarioId, silverBulletId) => {
        const bulletId = parseInt(silverBulletId, 10);
        setScenarios(prev => prev.map(scenario => {
            if (scenario.id === scenarioId) {
                const selectedSilverBullet = silverBullets.find(b => b.id === bulletId);
                return {
                    ...scenario,
                    selectedSilverBullet,
                    name: selectedSilverBullet ? selectedSilverBullet.name : scenario.name
                };
            }
            return scenario;
        }));
    };

    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const createScenariosFromDoc = async () => {
        setIsCreating(true);
        try {
            for (const scenario of scenarios) {
                if (!scenario.selectedSilverBullet) continue;

                const exists = existingScenarios.some(s =>
                    s.silverBulletId === scenario.selectedSilverBullet.id
                );

                if (exists) {
                    toast.info(`Skipping "${scenario.name}" - already exists`);
                    continue;
                }

                const scenarioData = {
                    silverBulletId: scenario.selectedSilverBullet.id,
                    silverBulletName: scenario.selectedSilverBullet.name,
                    description: scenario.description,
                    status: 'Pending'
                };

                await sdk.scenario.create(scenarioData);

                if (scenario.attackFlowImage) {
                    const imageFile = dataURLtoFile(
                        scenario.attackFlowImage,
                        `${scenario.name}_lightchart.png`
                    );
                    await sdk.silverBullets.uploadFile(
                        imageFile,
                        scenario.selectedSilverBullet.id,
                        'lightchart'
                    );
                }

                toast.success(`Created scenario "${scenario.name}" successfully!`);
            }
        } catch (error) {
            const errorMessage = error.response && error.response.data && error.response.data.error && error.response.data.error.message;
            toast.error(errorMessage);
        } finally {
            setIsCreating(false);
        }
    };

    const allScenariosConnected = scenarios.length > 0 &&
        scenarios.every(s => s.selectedSilverBullet !== null);

    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={10}>
                    <Card className="shadow-sm border-0">
                        <CardBody>
                            <CardTitle tag="h4" className="text-center mb-4">
                                Sigma Rule Importer
                            </CardTitle>

                            <FormGroup>
                                <Label for="sigmaUpload">Upload Sigma Rules DOCX</Label>
                                <Input
                                    type="file"
                                    id="sigmaUpload"
                                    accept=".docx"
                                    onChange={handleFileUpload}
                                    disabled={isLoading}
                                />
                                {error && <div className="text-danger mt-2">{error}</div>}
                            </FormGroup>

                            {scenarios.length > 0 && (
                                <div className="mt-4">
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <Button
                                            color="success"
                                            onClick={createScenariosFromDoc}
                                            disabled={isCreating || !allScenariosConnected}
                                        >
                                            {isCreating ? 'Creating...' : 'Import All Scenarios'}
                                        </Button>
                                        <div className="text-muted">
                                            {allScenariosConnected ?
                                                'All scenarios mapped ✓' :
                                                'Missing SilverBullet mappings ✗'}
                                        </div>
                                    </div>

                                    {scenarios.map((scenario) => {
                                        const exists = existingScenarios.some(s =>
                                            (scenario.selectedSilverBullet && s.silverBulletId === scenario.selectedSilverBullet.id)
                                        );

                                        return (
                                            <Card key={scenario.id} className="mb-4">
                                                <CardBody>
                                                    <div className={`mb-3 ${exists ? 'text-warning' :
                                                        scenario.selectedSilverBullet ? 'text-success' : 'text-danger'}`}>
                                                        <strong>
                                                            {exists ? '⚠ Exists' :
                                                                scenario.selectedSilverBullet ? '✓ Ready' :
                                                                    '✗ Needs mapping'}
                                                        </strong>
                                                    </div>

                                                    {!scenario.selectedSilverBullet && (
                                                        <FormGroup>
                                                            <Label>Map to SilverBullet: {scenario.name}</Label>
                                                            <Input
                                                                type="select"
                                                                value=""
                                                                onChange={(e) => handleSilverBulletSelect(scenario.id, e.target.value)}
                                                            >
                                                                <option value="">Select matching SilverBullet...</option>
                                                                {silverBullets.map(bullet => (
                                                                    <option key={bullet.id} value={bullet.id}>
                                                                        {bullet.name}
                                                                    </option>
                                                                ))}
                                                            </Input>
                                                        </FormGroup>
                                                    )}

                                                    <CardTitle tag="h5">
                                                        {scenario.selectedSilverBullet ?
                                                            scenario.selectedSilverBullet.name :
                                                            scenario.name}
                                                    </CardTitle>

                                                    <CardText>
                                                        <strong>Description:</strong>
                                                        <div className="text-muted mt-1">{scenario.description}</div>

                                                        <strong className="d-block mt-3">Detection Rule:</strong>
                                                        <code className="d-block text-primary mt-1">
                                                            {scenario.detectionRule}
                                                        </code>
                                                    </CardText>

                                                    {scenario.attackFlowImage && (
                                                        <div className="mt-3">
                                                            <strong>Attack Flow Diagram:</strong>
                                                            <img
                                                                src={scenario.attackFlowImage}
                                                                alt="Attack flow"
                                                                className="img-fluid mt-2 border rounded"
                                                                style={{ maxHeight: '300px' }}
                                                            />
                                                        </div>
                                                    )}
                                                </CardBody>
                                            </Card>
                                        );
                                    })}
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default SigmaRuleParser;