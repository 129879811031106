import React, { useState, useEffect } from 'react';
import { Button, FormGroup, Label, Input, Form, Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import sdk from 'api-sdk'; // Ensure correct import path
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import ImageModule from 'docxtemplater-image-module-free';
import { saveAs } from 'file-saver';
import englishTemplate from './englishTemplate.docx';

const ReportForge = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [runNumber, setRunNumber] = useState('');
    const [scenarios, setScenarios] = useState([]);
    const [loading, setLoading] = useState(false);
    const [payloads, setPayloads] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [firstDefenceRules, setFirstDefenceRules] = useState('');
    const [missingScenarios, setMissingScenarios] = useState([]);

    useEffect(() => {
        fetchCompanies();
    }, []);

    const fetchCompanies = async () => {
        try {
            const companiesData = await sdk.companies.getAll();
            setCompanies(companiesData);
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    };

    const fetchScenarios = async () => {
        if (!selectedCompany || !runNumber) {
            alert('Please select a company and enter a run number');
            return;
        }

        setLoading(true);
        setScenarios([]);
        setPayloads([]);
        setMissingScenarios([]);

        try {
            const scenariosData = await sdk.companies.getScenariosByCompanyAndRunNumber(selectedCompany, runNumber);
            const allRunHistories = await sdk.runHistory.getAll()
            const runHistories = allRunHistories.filter(
                (rh) => rh.companyName === selectedCompany && parseInt(rh.runNumber, 10) === parseInt(runNumber, 10)
            );

            // Extract the first defenceRules and update the state
            if (runHistories.length > 0) {
                setFirstDefenceRules(runHistories[0].defenceRules || "No defence rules available");
            } else {
                setFirstDefenceRules("No defence rules available");
            }

            // Find bullets with no scenarios
            const bulletIdsWithScenarios = new Set(scenariosData.scenarios.map((s) => s.silverBulletId));


            const bulletsWithoutScenarios = runHistories
                .filter((rh) => !bulletIdsWithScenarios.has(rh.bulletId))
                .map((rh) => rh.bulletName); // Use the bulletName field

            setMissingScenarios(bulletsWithoutScenarios);

            if (bulletsWithoutScenarios.length > 0) {
                return; 
            }


            setMissingScenarios(bulletsWithoutScenarios);

            if (bulletsWithoutScenarios.length > 0) {
                return; 
            }

            let rowCounter = 1;
            const scenariosWithData = await Promise.all(
                scenariosData.scenarios.map(async (scenario) => {

                    const response = await sdk.silverBullets.getDefRuleIds(scenario.silverBulletId);
                    const defRuleIds = response.defRuleIds;

                    let recommendations = [];
                    if (Array.isArray(defRuleIds)) {

                        recommendations = await Promise.all(
                            defRuleIds.map(async (defRuleId) => {
                                const defRuleText = await sdk.defRules.getDefRuleText(defRuleId);
                                const systemName = defRuleText.systemName || 'Unknown';
                                const mitigations = Array.isArray(defRuleText.mitigations)
                                    ? defRuleText.mitigations
                                    : [defRuleText.mitigations].filter(Boolean);

                                // Fetch the paragraph for the first mitigation
                                let paragraph = 'No paragraph available';
                                if (mitigations.length > 0) {
                                    try {
                                        const response = await sdk.defRules.getParagraphByTitle(defRuleId, "Details of the attack");
                                        // Extract the paragraph text from the response
                                        paragraph =
                                            response.paragraph && response.paragraph
                                                ? response.paragraph
                                                : 'No paragraph available';

                                    } catch (error) {
                                        console.error(`Error fetching paragraph for mitigation: ${mitigations[0]}`, error);
                                    }
                                }


                                return { systemName, mitigations, paragraph };
                            })
                        );
                    }

                    const payloadsForScenario = await sdk.silverBullets.getPayloads(scenario.silverBulletId);
                   
                    

                    const runHistoryEntry = runHistories.find(
                        (rh) => rh.bulletId === scenario.silverBulletId
                    );
                    

                    const status = runHistoryEntry ? runHistoryEntry.status : 'Unknown';
                    const categoryName = runHistoryEntry ? runHistoryEntry.categoryName : 'Unknown';

                    const payloadsData = payloadsForScenario
                        .map((payload) => ({
                            rowNumber: rowCounter++,
                            status,
                            categoryName,
                            details: '',
                            fileFormat: payload.fileFormat,
                            scenarioName: payload.name,
                            pass: status === 'Pass',
                            fail: status === 'Fail',
                        }))
                        .sort((a, b) => {
                            if (a.pass && !b.pass) return -1;
                            if (!a.pass && b.pass) return 1;
                            return 0;
                        });

                    setPayloads((prevPayloads) =>
                        [...prevPayloads, ...payloadsData].sort((a, b) => {
                            if (a.pass && !b.pass) return -1;
                            if (!a.pass && b.pass) return 1;
                            return 0;
                        })
                    );

                    if (status.toLowerCase() === 'fail') {
                        return null; // Return null to exclude
                    }

                    return {
                        ...scenario,
                        recommendations,
                    };
                })
            );
            const filteredScenariosWithData = scenariosWithData.filter(scenario => scenario !== null);
            


            setScenarios(filteredScenariosWithData);
        } catch (error) {
            console.error('Error fetching scenarios:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCompanyChange = (e) => {
        const companyName = e.target.value;
        const selected = companies.find(company => company.companyName === companyName);

        setSelectedCompany(companyName);
        setSelectedCompanyId(selected ? selected.companyID : '');
    };

    const handleRunNumberChange = (e) => {
        setRunNumber(e.target.value);
    };



    const generateDocx = async () => {
        if (missingScenarios.length > 0) {
            alert('Cannot generate report. The following bullets have no scenarios:\n' + missingScenarios.join(', '));
            return;
        }
        const templateResponse = await fetch(englishTemplate);
        const templateArrayBuffer = await templateResponse.arrayBuffer();
        const zip = new PizZip(templateArrayBuffer);

        const imageModuleOptions = {
            centered: true,
            getImage: (tagValue, tagName) => {
                if (tagName === 'imageTag' || tagName === 'companyimage') {
                    const byteCharacters = atob(tagValue);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    return new Uint8Array(byteNumbers);
                }
                throw new Error(`Unrecognized tag: ${tagName}`);
            },
            getSize: (img, tagValue, tagName) => {
                if (tagName === 'imageTag') {
                    return [616, 148];
                } else if (tagName === 'companyimage') {
                    return [416, 128];
                }
                return [100, 100];
            },
        };

        const doc = new Docxtemplater()
            .attachModule(new ImageModule(imageModuleOptions))
            .loadZip(zip);

        const today = new Date();
        const formattedDate = today.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

        const companyImageBase64 = await sdk.companies.getCompanyImageBase64(selectedCompanyId)
            .then(response => response.base64Image || response.imageData || response)
            .catch(() => '');

        const scenariosWithImages = await Promise.all(
            scenarios.map(async (scenario) => {
                try {
                    const { base64Chart } = await sdk.silverBullets.getChartBase64(scenario.silverBulletId);
                    return {
                        ...scenario,
                        chartImage: base64Chart,
                    };
                } catch {
                    return { ...scenario, chartImage: '' };
                }
            })
        );

        const passedPayloadsCount = payloads.filter(payload => payload.pass).length;

        doc.setData({
            companyName: selectedCompany,
            companyimage: companyImageBase64,
            runNumber,
            date: formattedDate,
            scenarios: scenariosWithImages.map((scenario, index) => {
                const isH = scenario.scenarioDifficulty === 'High';
                const isM = scenario.scenarioDifficulty === 'Medium';
                const isL = scenario.scenarioDifficulty === 'Low';
                const ImpactH = scenario.scenarioImpact === 'High';
                const ImpactM = scenario.scenarioImpact === 'Medium';
                const ImpactL = scenario.scenarioImpact === 'Low';

                return {
                    ...scenario,
                    scenarioNumber: index + 1,
                    scenarioTitle: scenario.silverBulletName,
                    scenarioDescription: scenario.description,
                    attackFlow: [{ imageTag: scenario.chartImage }],
                    recommendations: scenario.recommendations,
                    isH,
                    isM,
                    isL,
                    ImpactH,
                    ImpactM,
                    ImpactL,
                };
            }),
            payloads,
            passedPayloadsCount,
            firstDefenceRules
        });

        try {
            doc.render();
        } catch (error) {
            console.error('Error rendering document:', error);
        }

        const output = doc.getZip().generate({ type: 'blob' });
        saveAs(output, `${selectedCompany}_${runNumber}_Report.docx`);
    };


    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Card className="shadow-sm border-0">
                        <CardBody>
                            <CardTitle tag="h4" className="text-center mb-4">
                                Report Forge
                            </CardTitle>
                            <Form>
                                <FormGroup>
                                    <Label for="companySelect">Select Company</Label>
                                    <Input
                                        type="select"
                                        id="companySelect"
                                        value={selectedCompany}
                                        onChange={handleCompanyChange}
                                    >
                                        <option value="">Choose a company</option>
                                        {companies.map((company) => (
                                            <option key={company.companyID} value={company.companyName}>
                                                {company.companyName}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="runNumber">Run Number</Label>
                                    <Input
                                        type="text"
                                        id="runNumber"
                                        placeholder="Enter run number"
                                        value={runNumber}
                                        onChange={handleRunNumberChange}
                                    />
                                </FormGroup>
                                <Button color="primary" block onClick={fetchScenarios} disabled={loading}>
                                    {loading ? 'Loading...' : 'Fetch Scenarios'}
                                </Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>

                    {scenarios.length > 0 ? (

                        <Card className="shadow-sm border-0">
                            <Button color="success" onClick={generateDocx}>
                                Generate Report
                            </Button>
                            <CardBody>
                                <CardTitle tag="h5" className="text-center">
                                    Scenarios for Company: {selectedCompany} | Run Number: {runNumber}
                                </CardTitle>
                                {scenarios.map((scenario, index) => (
                                    <div key={index} className="mb-4">
                                        <CardText className="text-muted">
                                            {scenario.silverBulletName} - {scenario.description}
                                        </CardText>
                                        <ChartImage silverBulletId={scenario.silverBulletId} />
                                        {scenario.recommendations && scenario.recommendations.length > 0 && (
                                            <div className="mt-3">

                                                <h5>Recommendations:</h5>
                                                {scenario.recommendations.map((recommendation, idx) => (
                                                    <div key={idx} className="mb-2">
                                                        <h6><strong>Details of the attack:</strong></h6>
                                                        <p>{recommendation.paragraph}</p>
                                                        <p><strong>System Name: </strong> {recommendation.systemName}</p>
                                                        {recommendation.mitigations && recommendation.mitigations.length > 0 && (
                                                            <ul>
                                                                {recommendation.mitigations.map((mitigation, i) => (
                                                                    <li key={i}>{mitigation}</li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </CardBody>
                        </Card>
                    ) : missingScenarios.length > 0 ? (
                        <Card className="shadow-sm border-0">
                            <CardBody>
                                <CardTitle tag="h5" className="text-danger text-center">
                                    Missing Scenarios
                                </CardTitle>
                                {/* Replace CardText with div */}
                                <div>
                                    <p>The following bullets have no associated scenarios:</p>
                                    <ul>
                                        {missingScenarios.map((bullet, index) => (
                                            <li key={index}>{bullet}</li>
                                        ))}
                                    </ul>
                                </div>
                            </CardBody>
                        </Card>
                    ) : (
                        <Card className="shadow-sm border-0">
                            <CardBody>
                                <CardText className="text-center">
                                    No scenarios found for the selected company and run number.
                                </CardText>
                            </CardBody>
                        </Card>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

const ChartImage = ({ silverBulletId }) => {
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        const fetchImage = async () => {
            const url = await sdk.silverBullets.getChartLink(silverBulletId);
            setImageUrl(url);
        };
        fetchImage();
    }, [silverBulletId]);

    return (
        <>
            {imageUrl ? (
                <img src={imageUrl} alt={`Chart for SilverBullet ${silverBulletId}`} className="img-fluid" />
            ) : (
                <p>Chart image not available</p>
            )}
        </>
    );
};

export default ReportForge;
