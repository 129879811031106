import React from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    CustomInput,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    FormFeedback,
    UncontrolledAlert,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import sdk from 'api-sdk';
import vectors from '../../assets/data/attackVectors.json';
import DefRuleUpload from './defRuleUpload';
import PayloadUpload from './payloadUpload';
import DocUpload from './docUpload';
import TagContainer from './tagContainer';
import EDRs from '../../assets/data/edrs.json'
import Platforms from '../../assets/data/platforms.json'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const attackVectors = vectors.map(vector => vector.hide? vector.title + ' - to be removed' : vector.title);


const getSilverBullet = (bulletId) => {
    return sdk.silverBullets.getById(bulletId, {include: ['aptTags', 'techniques']}).then(bullet => {
        const copyBullet =  { ...bullet };
        delete copyBullet.aptTags;
        delete copyBullet.techniques;
        const originalBullet = bullet;
        const bulletsAptTags = bullet.aptTags.map(tag => ({ ...tag }));
        const bulletsTechniques = bullet.techniques.map(tech => ({ ...tech }));

        return {
            ...copyBullet,
            originalBullet,
            bulletsAptTags,
            bulletsTechniques
        };
    });
}

const processPayload = payload => {
    const tags = payload.softwareTags.map(tag => ({ ...tag }));
    const copy = { ...payload };
    copy.softwareTags = tags;
    copy.original = payload;
    return copy;
};

const getPayload = id => sdk.payloads.getById(id, {include: 'softwareTags'}).then(processPayload);

const getPayloads = (bulletId) => {
    return sdk.silverBullets.getPayloads(bulletId, {include: 'softwareTags'}).then(payloads => {
        return payloads.map(processPayload);
    });
}

const processDefRule = rule => {
    const copy = { ...rule };
    copy.edrTag = { ...rule.edrTag };
    copy.original = rule;
    return copy;
} 

const getDefRule = id => sdk.defRules.getById(id, {include: 'edrTag'}).then(processDefRule);

const getDefRules = (bulletId) => {
    return sdk.silverBullets.getDefRules(bulletId, {include: 'edrTag'}).then(rules => {
        return rules.map(processDefRule);
    });
}

class BulletEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            techniqueSearch: "",
            originalBullet: undefined,
            editing: true,
            uploadError: undefined,
            needForInternet: false,
            requireLocalAdmin: false,
            aptTags: [],
            softwareTags: [],
            edrTags: [],
            techniques: [],
            chosenPlatforms: null,
            // chosenEdrs: null,
            bulletsTechniques: [],
            bulletsAptTags: [],
            currentTag: '',
            currentTech: '',
            id: undefined,
            idError: undefined,
            name: undefined,
            nameError: undefined,
            attackVector: undefined,
            attackVectorError: undefined,
            status: undefined,
            statusError: undefined,
            creator: undefined,
            creatorError: undefined,
            description: undefined,
            descriptionError: undefined,
            payloads: undefined,
            defRules: undefined,
        };

        this.resetChanges = this.resetChanges.bind(this);
        this.addTech = this.addTech.bind(this);
        this.removeTech = this.removeTech.bind(this);
        this.addAptTag = this.addAptTag.bind(this);
        this.removeAptTag = this.removeAptTag.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.onDefRuleChange = this.onDefRuleChange.bind(this);
        this.revertPayload = this.revertPayload.bind(this);
        this.deletePayload = this.deletePayload.bind(this);
        this.submitPayload = this.submitPayload.bind(this);
        this.onPayloadChange = this.onPayloadChange.bind(this);
        this.addPayload = this.addPayload.bind(this);
        this.removePayload = this.removePayload.bind(this);
        this.addDefRule = this.addDefRule.bind(this);
        this.removeDefRule = this.removeDefRule.bind(this);
        this.revertDefRule = this.revertDefRule.bind(this);
        this.deleteDefRule = this.deleteDefRule.bind(this);
        this.submitDefRule = this.submitDefRule.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    resetChanges() {
        this.setState(state => {
            const newState = { ...state };
            const copy = { ...state.originalBullet };
            delete copy.aptTags;
            delete copy.techniques;
            Object.assign(newState, copy);
            newState.bulletsAptTags = state.originalBullet.aptTags.map(tag => ({ ...tag }));;
            newState.bulletsTechniques = state.originalBullet.techniques.map(tech => ({ ...tech }));
            return newState;
        });
    }

    addTech() {
        let newTech = this.state.techniques.find(tech => tech.id === parseInt(this.state.currentTech));
        if (!newTech || this.state.bulletsTechniques.find(tech => tech.id === newTech.id)) return;

        newTech.action = 'add';

        this.setState(state => {
            const newArr = [...state.bulletsTechniques];
            newArr.push(newTech);
            return {
                bulletsTechniques: newArr,
                currentTag: '',
            };
        });
    }

    removeTech(index) {
        if (this.state.bulletsTechniques[index].action === 'delete') {
            this.setState(state => {
                const newArr = [...state.bulletsTechniques];
                delete newArr[index].action;
                return {
                    bulletsTechniques: newArr,
                };
            });
        } else if (this.state.bulletsTechniques[index].action) {
            this.setState(state => {
                state.bulletsTechniques.splice(index,1);
                const arr = [...state.bulletsTechniques];
                return {
                    bulletsTechniques: arr,
                };
            });
        } else {
            this.setState(state => {
                const newArr = [...state.bulletsTechniques];
                newArr[index].action = 'delete';
                return {
                    bulletsTechniques: newArr,
                };
            });
        }
    }

    addAptTag() {
        let newTag = this.state.aptTags.find(tag => tag.aptName === this.state.currentTag);
        if(!newTag) {
            newTag = {
               action: 'create',
               aptName: this.state.currentTag
            };
        } else {
            newTag.action = 'add';
        }

        this.setState(state => {
            state.bulletsAptTags.push(newTag);
            const newArr = [...state.bulletsAptTags];
            return {
                bulletsAptTags: newArr,
                currentTag: '',
            };
        });
    }

    removeAptTag(index) {
        if (this.state.bulletsAptTags[index].action === 'delete') {
            this.setState(state => {
                const newArr = [...state.bulletsAptTags];
                delete newArr[index].action;
                return {
                    bulletsAptTags: newArr,
                };
            });
        } else if (this.state.bulletsAptTags[index].action) {
            this.setState(state => {
                state.bulletsAptTags.splice(index,1);
                const arr = [...state.bulletsAptTags];
                return {
                    bulletsAptTags: arr,
                };
            });
        } else {
            this.setState(state => {
                const newArr = [...state.bulletsAptTags];
                newArr[index].action = 'delete';
                return {
                    bulletsAptTags: newArr,
                };
            });
        }
    }

    onPayloadChange(index, action, value) {
        this.setState(state => {
            const payload = state.payloads[index];

            if (!payload) return state;

            payload.errors = null;

            if (action === 'addTag') {
                let newTag = this.state.softwareTags.find(tag => tag.softwareName === value);
                if(newTag) {
                    newTag = { ...newTag };
                    newTag.action = 'add';
                } else {
                    newTag = {
                        isNew: true,
                        softwareName: value,
                        action: 'create'
                    };
                }

                payload.softwareTags.push(newTag);
            } else if (action === 'removeTag') {
                const tag = payload.softwareTags[value];
                if (tag.action === 'delete') {
                    delete tag.action;
                } else if (tag.action) {
                    payload.softwareTags.splice(value, 1);
                } else {
                    tag.action = 'delete';
                }
            } else {
                payload[action] = value;
            }

            const newArray = [...state.payloads];

            return { payloads: newArray };
        });
    }

    addPayload() {
        this.setState(state => {
            const newArray = [...state.payloads];

            newArray.push({softwareTags: []});

            return {payloads: newArray};
        });
    }

    removePayload(index) {
        this.setState(state => {
            const newArray = [...state.payloads];
            newArray.splice(index, 1);

            return {payloads: newArray};
        })
    }

    deletePayload(id, index) {
        this.setState({ editing: true });
        return sdk.payloads.delete(id).then(() => {
            this.removePayload(index);
            this.setState({ editing: false });
        });
    }

    submitPayload(index) {
        const payload = this.state.payloads[index];

        const errors = {};
        if(!payload.name) {
            errors.name = 'Name required';
        }

        if(!payload.id && !payload.file) {
            errors.file = 'File required';
        }

        if(!payload.fileFormat) {
            errors.fileFormat = 'File format required';
        }

        if (Object.keys(errors).length > 0) {
            this.setState(state => {
                const newArray = [ ...state.payloads ];
                newArray[index].errors = errors;
                return { payload: newArray }
            });
        } else {
            this.setState({ editing: true});
            let payloadPromise;
            const newPayload = {
                createdAt: payload.createdAt || undefined,
                name: payload.name,
                fileName: payload.file? payload.file.name : payload.fileName,
                fileFormat: payload.fileFormat
            };
            if (payload.id) {
                payloadPromise = sdk.payloads.update(payload.id, newPayload);
            } else {
                payloadPromise = sdk.silverBullets.postPayload(this.props.match.params.bulletId, newPayload);
            }
            
            return payloadPromise.then(p => {
                payload.id = p.id
                const promises = [];

                if (payload.file) {
                    promises.push(sdk.payloads.uploadFile(payload.file, p.id));
                }

                payload.softwareTags.forEach(tag => {
                    if (tag.action === 'create') {
                        promises.push(sdk.payloads.relateNewSoftwareTag(p.id, tag.softwareName));
                    } else if (tag.action === 'add') {
                        promises.push(sdk.payloads.relateSoftwareTag(p.id, tag.id));
                    } else if (tag.action === 'delete') {
                        promises.push(sdk.payloads.deleteSoftwareTagRelation(p.id, tag.id));
                    }
                });
                return Promise.all(promises);
            }).then(() => getPayload(payload.id))
            .then(newPayload => this.setState(state => {
                const payloads = state.payloads;
                newPayload = processPayload(newPayload);
                payloads[index] = newPayload;
                toast.success("Payload Edit Successfully");
                return { payloads, editing: false };
            })).catch(e => this.setState(state => {
                console.log(e)
                const newArray = [ ...state.payloads ];
                newArray[index].errors = {upload: 'Something went wrong'};
                return { payloads: newArray, editing: false };
            }));
        }        
    }

    revertPayload(index) {
        const original = this.state.payloads[index].original;
        const tagsCopy = original.softwareTags.map(t => ({ ...t }));
        this.setState(state => {
            const newArray = [ ...state.payloads ];
            newArray[index] = { ...original };
            newArray[index].softwareTags = tagsCopy;

            return { payloads: newArray };
        })
    }

    onDefRuleChange(index, action, value) {
        this.setState(state => {
            const defRule = state.defRules[index];

            if(!defRule) return state;

            defRule.errors = null;

            if (action === 'edrName') {
                defRule.edrTag = this.state.edrTags.find(tag => tag.edrName === value);

                if (!defRule.edrTag) {
                    defRule.edrTag = {
                        isNew: true,
                        edrName: value
                    };
                }
            } else {
                defRule[action] = value;
            }

            const newArray = [...state.defRules];
            return { defRules: newArray };
        });

    }

    addDefRule() {
        this.setState(state => {
            const newArray = [...state.defRules];

            newArray.push({});

            return {defRules: newArray};
        });
    }

    removeDefRule(index) {
        this.setState(state => {
            const newArray = [...state.defRules];
            newArray.splice(index, 1);

            return {defRules: newArray};
        });
    }

    deleteDefRule(id, index) {
        this.setState({ editing: true });
        return sdk.defRules.delete(id).then(() => {
            this.removeDefRule(index);
            this.setState({ editing: false });
        });
    }

    submitDefRule(index) {
        const rule = this.state.defRules[index];

        const errors = {};
        if(!rule.name) {
            errors.name = 'Name required';
        }

        if(!(rule.edrTag && rule.edrTag.edrName)) {
            errors.edrTag = 'EDR tag required';
        }

        if(!rule.id && !rule.file) {
            errors.file = 'File required';
        }

        if (Object.keys(errors).length > 0) {
            this.setState(state => {
                const newArray = [ ...state.defRules ];
                newArray[index].errors = errors;
                return { defRules: newArray }
            });
        } else {
            this.setState({ editing: true});
            let tagPromise;
            if (rule.edrTag.isNew) {
                tagPromise = sdk.edrTags.create({edrName: rule.edrTag.edrName});
            } else {
                tagPromise = Promise.resolve(rule.edrTag);
            }

            const rulePromise = tagPromise.then(edrTag => {
                const newRule = {
                    name: rule.name,
                    edrTagId: edrTag.id,
                    fileName: rule.file? rule.file.name : rule.fileName,
                };
                if (rule.id) {
                    return sdk.defRules.update(rule.id, newRule);
                } else {
                    return sdk.silverBullets.postDefRule(this.props.match.params.bulletId, newRule);
                }
            });
            return rulePromise.then(defRule => {
                rule.id = defRule.id;
                return new Promise(resolve => {
                    this.setState(state => {
                        const newArray = [ ...state.defRules ];
                        const copy = { ...defRule };
                        copy.edrTag = { ...defRule.edrTag };
                        copy.original = defRule;
                        newArray[index] = copy;
                        return { defRules: newArray };
                    }, () => resolve(defRule));
                });
            }).then(defRule => {
                if (rule.file) {
                    return sdk.defRules.uploadFile(rule.file, defRule.id);
                } else {
                    return defRule;
                }
                
            }).then(() => getDefRule(rule.id))
            .then(newRule => this.setState(state => {
                const defRules = state.defRules;
                newRule = processDefRule(newRule);
                defRules[index] = newRule;
                toast.success("Def Rule Edit Successfully");
                return { defRules, editing: false };
            })).catch(e => this.setState(state => {
                const newArray = [ ...state.defRules ];
                newArray[index].errors = {upload: 'Something went wrong'};
                return { defRules: newArray, editing: false };
            }));
        }        
    }

    revertDefRule(index) {
        const original = this.state.defRules[index].original;
        const tagCopy = { ...original.edrTag };
        this.setState(state => {
            const newArray = [ ...state.defRules ];
            newArray[index] = { ...original };
            newArray[index].edrTag = tagCopy;

            return { defRules: newArray };
        })
    }

    validateForm() {
        const errors = {};

        if(!this.state.id) {
            errors.idError = 'Id required';
        }

        if(!this.state.name) {
            errors.nameError = 'Name required';
        }

        if(!this.state.attackVector) {
            errors.attackVectorError = 'Attack vector required';
        }

        if(!this.state.status) {
            errors.statusError = 'Status required';
        }

        if(!this.state.creator) {
            errors.creatorError = 'Creator required';
        }

        if(!this.state.description) {
            errors.descriptionError = 'Description required';
        }

        return {
            isValid: Object.keys(errors).length === 0,
            errors
        };
    }

    handleSubmit() {
        const {isValid, errors} = this.validateForm();
        
        if(!isValid) {
            this.setState(errors);
            return;
        }

        // if (this.state.chosenEdrs != null && this.state.bulletEdrs != null) {
        //     const updatedChosenEdrs = {
        //         ...this.state.chosenEdrs,
        //         silverBulletId: this.state.id
        //     };
        //     const edrsId = this.state.bulletEdrs.id;
        //     sdk.bulletsEdrs.update(edrsId, updatedChosenEdrs);
        //     this.setState({ chosenEdrs: updatedChosenEdrs });
        // } else if (this.state.chosenEdrs != null) {
        //     const updatedChosenEdrs = {
        //         ...this.state.chosenEdrs,
        //         silverBulletId: this.state.id
        //     };
        //     sdk.bulletsEdrs.create(updatedChosenEdrs);
        //     this.setState({ chosenEdrs: updatedChosenEdrs });
        // }
        
        if (this.state.chosenPlatforms != null && this.state.bulletPlatforms != null) {
            const updatedChosenPlatforms = {
                ...this.state.chosenPlatforms,
                silverBulletId: this.state.id
            };
            const platformsId = this.state.bulletPlatforms.id;
            sdk.bulletPlatforms.update(platformsId, updatedChosenPlatforms);
            this.setState({ chosenPlatforms: updatedChosenPlatforms });
        } else if (this.state.chosenPlatforms != null) {
            const updatedChosenPlatforms = {
                ...this.state.chosenPlatforms,
                silverBulletId: this.state.id
            };
            sdk.bulletPlatforms.create(updatedChosenPlatforms);
            this.setState({ chosenPlatforms: updatedChosenPlatforms });
        }
        

        const newBullet = {
            name: this.state.name,
            attackVector: this.state.attackVector,
            description: this.state.description,
            requireLocalAdmin: this.state.requireLocalAdmin,
            needForInternet: this.state.needForInternet,
            status: this.state.status,
            creator: this.state.creator,
        };

        this.setState({ editing: true });
        sdk.silverBullets.update(this.props.match.params.bulletId, newBullet).then(bullet => {
            const promises = [];

            this.state.bulletsAptTags.forEach(tag => {
                if (tag.action === 'add') {
                    promises.push(sdk.silverBullets.relateAptTag(bullet.id, tag.id));
                } else if (tag.action === 'create') {
                    promises.push(sdk.silverBullets.relateNewAptTag(bullet.id, tag.aptName));
                } else if (tag.action === 'delete') {
                    promises.push(sdk.silverBullets.deleteAptTagRelation(bullet.id, tag.id));
                }
            });

            this.state.bulletsTechniques.forEach(tech => {
                if (tech.action === 'add') {
                    promises.push(sdk.silverBullets.relateTechnique(bullet.id, tech.id));
                } else if (tech.action === 'delete') {
                    promises.push(sdk.silverBullets.deleteTechniqueRelation(bullet.id, tech.id));
                }
            });

            return Promise.all(promises);
        }).then(() => getSilverBullet(this.props.match.params.bulletId))
        .then(editedBullet => {
            this.setState({
                ...editedBullet,
                editing: false
            });
            toast.success("Silver Bullet Edit Successfully");
        }).catch(e => {
            this.setState({
                editing: false,
                uploadError: e.toString()
            });
        });
    }

    componentDidMount() {
        const bulletId = this.props.match.params.bulletId;
        Promise.all([
            sdk.aptTags.get(),
            sdk.softwareTags.get(),
            sdk.edrTags.get(),
            getSilverBullet(bulletId),
            getPayloads(bulletId),
            getDefRules(bulletId),
            sdk.techniques.get(),
            sdk.bulletsEdrs.getByBulletId(bulletId),
            sdk.bulletPlatforms.getByBulletId(bulletId)
        ]).then(data => {
            const bulletEdrs = data[7][0];
            //const chosenEdrs = { ...bulletEdrs };
            //delete chosenEdrs.id; // Remove the id property, we don't need it for this context
            //delete chosenEdrs.silverBulletId; // Remove the silverBulletId property, we don't need it for this context
    
            const bulletPlatforms = data[8][0];
            const chosenPlatforms = { ...bulletPlatforms };
            delete chosenPlatforms.id; // Remove the id property, we don't need it for this context
            delete chosenPlatforms.silverBulletId; // Remove the silverBulletId property, we don't need it for this context
    
            this.setState(state => {
                const newState = { ...state };
                Object.assign(newState, data[3]);
    
                newState.aptTags = data[0];
                newState.softwareTags = data[1];
                newState.edrTags = data[2];
                newState.techniques = data[6];
    
                newState.payloads = data[4];
                newState.defRules = data[5];
    
                newState.editing = false;
                newState.bulletEdrs = bulletEdrs;
                //newState.chosenEdrs = chosenEdrs;
                newState.bulletPlatforms = bulletPlatforms;
                newState.chosenPlatforms = chosenPlatforms;
                return newState;
            });
        });
    }
    
    

    render() {

        if(!this.state.id) {
            return (
                <Card>
                    <CardTitle className="bg-light border-bottom p-3 mb-0">
                        Edit Silver Bullet
                        <span className="float-right">
                            <Link to={`/silver-bullet-management`} onClick={e => e.stopPropagation()}>
                                <Button className='btn-outline-danger' size='sm'>
                                    <i className='fas fa-times'/>
                                </Button>
                            </Link>
                        </span>
                    </CardTitle>
                    <CardBody>
                        <h1 className='text-center'><i className='fas fa-spin fa-spinner'/></h1>
                    </CardBody>
                </Card>
            )
        }

        return (
            <div>
                <ToastContainer />
                <Card>
                    <CardTitle className="bg-light border-bottom p-3 mb-0">
                        Edit Silver Bullet
                        <span className="float-right">
                            <Link to={`/silver-bullet-management`} onClick={e => e.stopPropagation()}>
                                <Button className='btn-outline-danger' size='sm'>
                                    <i className='fas fa-times'/>
                                </Button>
                            </Link>
                        </span>
                    </CardTitle>
                    <CardBody>
                        <Form onSubmit={e => e.preventDefault()}>
                            <Row>
                                <Col md='6'>
                                    <Row>
                                        <Col md='2'>
                                            <FormGroup>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Id</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="number"
                                                        min="1"
                                                        disabled={true}
                                                        invalid={!!this.state.idError}
                                                        value={this.state.id}
                                                    />
                                                    <FormFeedback>
                                                        {this.state.idError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md='5'>
                                            <FormGroup>
                                                <InputGroup>
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>Name</InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        type="text"
                                                        value={this.state.name}
                                                        disabled={this.state.editing}
                                                        invalid={!!this.state.nameError}
                                                        onChange={e => this.setState({name: e.target.value, nameError: undefined})}
                                                    />
                                                    <FormFeedback>
                                                        {this.state.nameError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md='4'>
                                        <FormGroup>
                                            <InputGroup>
                                                <Input
                                                    type="select"
                                                    value={this.state.status}
                                                    disabled={this.state.creating}
                                                    invalid={!!this.state.statusError}
                                                    onChange={e => this.setState({status: e.target.value, statusError: undefined})}
                                                    // defaultValue="Select Status"
                                                >
                                                    <option key='attackVector-placeholder' default disabled>Select Status</option>
                                                    <option>online</option>
                                                    <option>offline</option>
                                                </Input>
                                                <FormFeedback>
                                                    {this.state.statusError}
                                                </FormFeedback>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    </Row>
                                    <Row>
                                        <Col md='4'>
                                            <FormGroup>
                                                <InputGroup>
                                                    <Input
                                                        type="select"
                                                        value={this.state.attackVector}
                                                        disabled={this.state.editing}
                                                        invalid={!!this.state.attackVectorError}
                                                        onChange={e => this.setState({attackVector: e.target.value, attackVectorError: undefined})}
                                                        // defaultValue="Select Attack Vector"
                                                    >
                                                        <option key='attackVector-placeholder' default disabled>Select Attack Vector</option>
                                                        {attackVectors.map(vector => <option key={`${vector}-option`}>{vector}</option>)}
                                                    </Input>
                                                    <FormFeedback>
                                                        {this.state.attackVectorError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <CustomInput
                                                className="mt-2"
                                                type="checkbox"
                                                id="internetCheckbox"
                                                label="Requires internet connection"
                                                checked={this.state.needForInternet}
                                                disabled={this.state.editing}
                                                onChange={() => this.setState(state => ({needForInternet: !state.needForInternet}))}
                                            />
                                        </Col>
                                        <Col md="4">
                                            <CustomInput
                                                className="mt-2"
                                                type="checkbox"
                                                id="localAdminCheckbox"
                                                label="Requires local admin"
                                                disabled={this.state.editing}
                                                checked={this.state.requireLocalAdmin}
                                                onChange={() => this.setState(state => ({requireLocalAdmin: !state.requireLocalAdmin}))}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                        <CardTitle className="bg-light border-bottom p-3 mt-3">
                                            Select the relevant Platforms
                                        </CardTitle>
                                        <FormGroup>
                                            {Platforms.map(platform => 
                                                <div className="form-check form-check-inline" key={platform.name}>
                                                    <CustomInput
                                                        type="checkbox"
                                                        id={platform.name}
                                                        label={platform.name}
                                                        defaultChecked={this.state.bulletPlatforms ? this.state.bulletPlatforms[platform.propName] : false}
                                                        onChange={e => {
                                                            const chosenPlatforms = { ...this.state.chosenPlatforms };
                                                            chosenPlatforms[platform.propName] = e.target.checked;
                                                            this.setState({ chosenPlatforms });
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </FormGroup>
                                        </Col>

                                        <Col md="4">
                                            <CardTitle className="bg-light border-bottom p-3 mt-3">
                                                Creator
                                            </CardTitle>
                                            <FormGroup>
                                                <Input 
                                                    type="select"
                                                    className="form-control"
                                                    value={this.state.creator}
                                                    onChange={e => this.setState({ creator: e.target.value, creatorError: undefined})}
                                                    invalid={!!this.state.creatorError}
                                                >
                                                    <option value="">Select a Creator</option>
                                                    <option value="Hai Vaknin">Hai Vaknin</option>
                                                    <option value="Barak Sofir">Barak Sofir</option>
                                                    <option value="Uriel Kosayev">Uriel Kosayev</option>
                                                    <option value="Tamir Yehuda">Tamir Yehuda</option>
                                                    <option value="Naor Evgi">Naor Evgi</option>
                                                    <option value="Tzach Benita">Tzach Benita</option>
                                                    <option value="Nathaniel Licht">Nathaniel Licht</option>
                                                    <option value="Danel Yehuda">Danel Yehuda</option>
                                                </Input>
                                                <FormFeedback>
                                                    {this.state.creatorError}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className='mb-0'>
                                                <InputGroup>
                                                    <Input
                                                        className="mt-3"
                                                        type="textarea"
                                                        rows="6"
                                                        value={this.state.description}
                                                        placeholder="Write a description..."
                                                        disabled={this.state.editing}
                                                        invalid={!!this.state.descriptionError}
                                                        onChange={e => this.setState({description: e.target.value, descriptionError: undefined})}
                                                    />
                                                    <FormFeedback>
                                                        {this.state.descriptionError}
                                                    </FormFeedback>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md='6'>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>Techniques</InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="text" placeholder="Search Technique" onChange={e => this.setState({techniqueSearch: e.target.value.toLowerCase()}) && this.changeTech}/>
                                                <Input
                                                    type="select"
                                                    // disabled={this.state.editing}
                                                    value={this.state.currentTech || undefined}
                                                    onChange={e => this.setState({currentTech: e.target.value})}
                                                    defaultValue="Select Technique"
                                                >
                                                    <option key='technique-placeholder' default >Select Technique</option>
                                                    {
                                                        this.state.techniques.filter(t => t.attackVector === this.state.attackVector && (t.name.toLowerCase().includes(this.state.techniqueSearch) || t.mitreId.toLowerCase().includes(this.state.techniqueSearch)))
                                                        .map(t => <option key={`technique-${t.id}-option`} value={t.id}>{t.mitreId} - {t.name}</option>)
                                                    }
                                                </Input>
                                                <InputGroupAddon addonType="append">
                                                    <Button onClick={this.addTech}>Add</Button>
                                                </InputGroupAddon>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <TagContainer
                                                title='Techniques'
                                                tagColor='info'
                                                tags={this.state.bulletsTechniques}
                                                nameProp='name'
                                                onRemove={index => this.removeTech(index)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className='mt-3'>
                                                <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>APT tags</InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="text"
                                                    list="aptTags"
                                                    placeholder="APT name"
                                                    value={this.state.currentTag}
                                                    disabled={this.state.editing}
                                                    onChange={e => this.setState({currentTag: e.target.value})}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button onClick={this.addAptTag}>Add</Button>
                                                </InputGroupAddon>
                                                <datalist id="aptTags">
                                                    {this.state.aptTags.map(tag => (
                                                        <option key={tag.aptName + '-option'}>{tag.aptName}</option>
                                                    ))}
                                                </datalist>
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <TagContainer
                                                title='APT tags'
                                                tags={this.state.bulletsAptTags}
                                                nameProp='aptName'
                                                onRemove={index => this.removeAptTag(index)}
                                            />
                                        </Col>
                                    </Row>
    
                                    {/* <Row>
                                        <Col>
                                            <CardTitle className="bg-light border-bottom p-3 mt-3">
                                                Select the relevant Defense Systems
                                            </CardTitle>
                                            <FormGroup>
                                                {EDRs.map(edr => 
                                                    <div className="form-check form-check-inline" key={edr.name}>
                                                        <CustomInput
                                                            type="checkbox"
                                                            id={edr.name}
                                                            label={edr.name}
                                                            defaultChecked={this.state.chosenEdrs ? this.state.chosenEdrs[edr.propName] : false}
                                                            onChange={e => {
                                                                const chosenEdrs = { ...this.state.chosenEdrs };
                                                                chosenEdrs[edr.propName] = e.target.checked ? true : null;
                                                                this.setState({ chosenEdrs });
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </FormGroup>  
                                        </Col>
                                    </Row> */}
                                </Col>
                            </Row>
                            
                            <Row className="mt-4">
                                <Col xl={{ size: 2, offset: 5 }} lg={{ size: 4, offset: 4 }} md='10'>
                                    {this.state.uploadError?
                                        <UncontrolledAlert className="mt-2 mb-0" color="danger">
                                            {this.state.uploadError}
                                        </UncontrolledAlert>
                                    : null}
                                    <FormGroup className='mb-0'>
                                        <Button onClick={this.handleSubmit} color="secondary" size="lg" block disabled={this.state.editing}>
                                            {!this.state.editing? "Apply changes" : <i className="fas fa-spin fa-spinner"/>}
                                        </Button>
                                    </FormGroup>
                                </Col>
                                <Col xl={{ size: 1, offset: 4 }} lg={{ size: 2, offset: 2 }} md='2'>
                                    <Button color="danger" size="lg" block disabled={this.state.editing} onClick={this.resetChanges}>
                                        <i className="fas fa-redo"/>
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
                <Row>
                    <Col md='6'>
                        <DocUpload type='description' name='Description HTML' bulletId={this.props.match.params.bulletId}/>
                    </Col>
                    <Col md='6'>
                        <DocUpload type='intel' name='Intelligence HTML' bulletId={this.props.match.params.bulletId}/>
                    </Col>
                </Row>
                <Row>
                    <Col md='6'>
                        <DocUpload type='instructions' name='Instructions HTML' bulletId={this.props.match.params.bulletId}/>
                    </Col>
                    <Col md='6'>
                        <DocUpload type='chart' name='Chart png' bulletId={this.props.match.params.bulletId}/>
                    </Col>
                </Row>
                {this.state.payloads.map((payload, i) =>
                    <Form onSubmit={e => e.preventDefault()} key={`payload-${i}`}>
                        <PayloadUpload
                            editor
                            index={i}
                            payload={payload}
                            disabled={this.state.editing}
                            onChange={this.onPayloadChange}
                            onRemove={this.removePayload}
                            onDelete={this.deletePayload}
                            onSubmit={this.submitPayload}
                            onRevert={this.revertPayload}
                            softwareTags={this.state.softwareTags}
                        />
                    </Form>
                )}
                {this.state.defRules.map((defRule, i) =>
                    <Form onSubmit={e => e.preventDefault()} key={`defRule-${i}`}>
                        <DefRuleUpload
                            editor
                            index={i}
                            defRule={defRule}
                            disabled={this.state.editing}
                            onChange={this.onDefRuleChange}
                            onRemove={this.removeDefRule}
                            onDelete={this.deleteDefRule}
                            onSubmit={this.submitDefRule}
                            onRevert={this.revertDefRule}
                            edrTags={this.state.edrTags}
                            edrs={EDRs}
                        />
                    </Form>
                )}
                <Row className="mt-2">
                    <Col xl={{ size: 2, offset: 4 }} lg={{ size: 4, offset: 2 }}  md='6' >
                        <FormGroup>
                            <Button color="danger" size="lg" block disabled={this.state.editing} onClick={this.addPayload}>
                                Add payload variant
                            </Button>
                        </FormGroup>
                    </Col>
                    <Col xl='2' lg='4' md='6'>
                        <FormGroup>
                            <Button color="info" size="lg" block disabled={this.state.editing} onClick={this.addDefRule}>
                                Add defence rule
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
                
            </div>
        );
    }
}

export default BulletEditor;