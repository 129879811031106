import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  MenuItem,
  Typography,
  Container,
  Grid,
  Paper,
  Autocomplete,
  Tabs,
  Tab,
  Box,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import sdk from 'api-sdk';
import { toast } from 'react-toastify';
import DocxParser from '../../components/DocxParser/DocxParser';
import SigmaRuleParser from '../../components/SigmaRuleParser/SigmaRuleParser';
import { styled } from '@mui/material/styles';

// Custom Styled Tab
const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 120,
    fontWeight: 600,
    fontSize: '1rem',
    marginRight: theme.spacing(1),
    color: '#B771E5',
    '&.Mui-selected': {
      color: '#B771E5',
      boxShadow: 'none', // Remove box shadow on focus
      outline: 'none', // Remove outline on focus
    },
    '&:hover': {
      color: '#7E5CAD',
      opacity: 1,
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'transparent', // Remove background color on focus
      boxShadow: 'none', // Remove box shadow on focus
      outline: 'none', // Remove outline on focus
    },
  })
);

// Helper component for Tab Panels
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

// Create a dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    background: {
      paper: '#121212',
      default: '#303030',
    },
  },
  typography: {
    h4: {
      color: '#ffffff',
    },
  },
});

const ScenarioCreation = () => {
  const [tabValue, setTabValue] = useState(0);
  const [form, setForm] = useState({
    silverBulletId: '',
    silverBulletName: '',
    scenarioDifficulty: '',
    scenarioImpact: '',
    tactic: '',
    description: '',
    status: 'Pending',
  });
  const [silverBullets, setSilverBullets] = useState([]);
  const [selectedBullet, setSelectedBullet] = useState(null);
  const [errors, setErrors] = useState({});
  const [lightChartFile, setLightChartFile] = useState(null);

  useEffect(() => {
    const fetchSilverBullets = async () => {
      try {
        const response = await sdk.silverBullets.get();
        setSilverBullets(response);
      } catch (error) {
        console.error('Error fetching SilverBullets:', error);
      }
    };

    fetchSilverBullets();
  }, []);

  const difficultyOptions = ['Low', 'Medium', 'High', 'נמוך', 'בינוני', 'גבוה'];
  const impactOptions = ['Low', 'Medium', 'High', 'נמוך', 'בינוני', 'גבוה'];

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleBulletChange = (event, newValue) => {
    setSelectedBullet(newValue);
    if (newValue) {
      setForm({
        ...form,
        silverBulletId: newValue.id,
        silverBulletName: newValue.name,
      });
    } else {
      setForm({
        ...form,
        silverBulletId: '',
        silverBulletName: '',
      });
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!form.silverBulletId) newErrors.silverBulletId = 'SilverBullet ID is required';
    if (!form.silverBulletName) newErrors.silverBulletName = 'SilverBullet name is required';
    if (!form.scenarioDifficulty) newErrors.scenarioDifficulty = 'Difficulty is required';
    if (!form.scenarioImpact) newErrors.scenarioImpact = 'Impact is required';
    // Uncomment if the light mode chart is required
    // if (!lightChartFile) newErrors.lightChartFile = 'Light mode chart file is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      await sdk.scenario.create(form);
      if (lightChartFile) {
        await sdk.silverBullets.uploadFile(lightChartFile, form.silverBulletId, 'lightchart');
      }
      toast.success('Scenario created successfully!');
      setForm({
        silverBulletId: '',
        silverBulletName: '',
        scenarioDifficulty: '',
        scenarioImpact: '',
        tactic: '',
        description: '',
        status: 'Pending',
      });
      setSelectedBullet(null);
      setLightChartFile(null);
    } catch (error) {
      console.error('Error creating scenario:', error);
      const errorMessage =
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message;
      if (errorMessage) {
        toast.error(errorMessage);
      } else {
        toast.error('Failed to create scenario. Please try again later.');
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Container maxWidth="md">
        <Paper elevation={3} style={{ marginTop: '2rem' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <CustomTab label="Manual Creation" {...a11yProps(0)} />
            <CustomTab label="DOCX Scenario Parser" {...a11yProps(1)} />
            <CustomTab label="Sigma Rule Importer" {...a11yProps(2)} />
          </Tabs>

          {/* Manual Creation Tab */}
          <TabPanel value={tabValue} index={0}>
            <Typography variant="h4" gutterBottom>
              Create a New Scenario
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {/* SilverBullet Name */}
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    options={silverBullets}
                    getOptionLabel={(option) => option.name || ''}
                    value={selectedBullet}
                    onChange={handleBulletChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="SilverBullet Name"
                        error={!!errors.silverBulletName}
                        helperText={errors.silverBulletName || ''}
                        required
                      />
                    )}
                  />
                </Grid>

                {/* SilverBullet ID (Disabled) */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="SilverBullet ID"
                    name="silverBulletId"
                    value={form.silverBulletId}
                    onChange={handleChange}
                    error={!!errors.silverBulletId}
                    helperText={errors.silverBulletId || ''}
                    disabled
                    required
                  />
                </Grid>

                {/* Scenario Difficulty */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Scenario Difficulty"
                    name="scenarioDifficulty"
                    value={form.scenarioDifficulty}
                    onChange={handleChange}
                    error={!!errors.scenarioDifficulty}
                    helperText={errors.scenarioDifficulty || ''}
                    required
                  >
                    {difficultyOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* Scenario Impact */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Scenario Impact"
                    name="scenarioImpact"
                    value={form.scenarioImpact}
                    onChange={handleChange}
                    error={!!errors.scenarioImpact}
                    helperText={errors.scenarioImpact || ''}
                    required
                  >
                    {impactOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* Tactic */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Tactic"
                    name="tactic"
                    value={form.tactic}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Description */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    value={form.description}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>

                {/* Light Mode Chart Upload */}
                <Grid item xs={12}>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="light-chart-upload"
                    type="file"
                    onChange={(e) => setLightChartFile(e.target.files[0])}
                  />
                  <label htmlFor="light-chart-upload">
                    <Button variant="contained" component="span">
                      Upload Light Mode Chart
                    </Button>
                    {lightChartFile && (
                      <Typography variant="body2" style={{ marginTop: '8px' }}>
                        {lightChartFile.name}
                      </Typography>
                    )}
                  </label>
                  {errors.lightChartFile && (
                    <Typography color="error" variant="body2">
                      {errors.lightChartFile}
                    </Typography>
                  )}
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Create Scenario
                  </Button>
                </Grid>
              </Grid>
            </form>
          </TabPanel>

          {/* DOCX Scenario Parser Tab */}
          <TabPanel value={tabValue} index={1}>
            <DocxParser />
          </TabPanel>

          {/* Sigma Rule Importer Tab */}
          <TabPanel value={tabValue} index={2}>
            <SigmaRuleParser />
          </TabPanel>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default ScenarioCreation;
