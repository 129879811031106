import React, { useState, useEffect } from 'react';
import { Button, Input, FormGroup, Label, Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import mammoth from 'mammoth';
import { v4 as uuidv4 } from 'uuid';
import sdk from 'api-sdk';
import { toast } from 'react-toastify';

const DocxParser = () => {
  const [scenarios, setScenarios] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [silverBullets, setSilverBullets] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [existingScenarios, setExistingScenarios] = useState([]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [bulletsResponse, scenariosResponse] = await Promise.all([
          sdk.silverBullets.get(),
          sdk.scenario.get()
        ]);
        setSilverBullets(bulletsResponse);
        setExistingScenarios(scenariosResponse);
      } catch (error) {
        console.error('Error loading initial data:', error);
        toast.error('Failed to load initial data');
      }
    };
    fetchInitialData();
  }, []);

  const extractScenarios = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const elements = Array.from(doc.body.children);

    const scenarios = [];
    let currentScenario = null;
    let isInDescription = false;
    const endHeaders = ['attack flow', 'proof of concept', 'mitigations'];

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      const tagName = element.tagName.toLowerCase();
      const text = element.textContent.trim();
      const lowerText = text.toLowerCase();

      if (tagName.match(/h[1-3]/i) && lowerText.includes('scenario')) {
        if (currentScenario) scenarios.push(currentScenario);
        currentScenario = {
          id: uuidv4(),
          name: text.replace(/(Scenario \d+ - )/i, ''),
          difficulty: '',
          impact: '',
          tactic: '',
          description: '',
          attackFlowImage: null,
          selectedSilverBullet: null
        };
        isInDescription = false;
      } else if (currentScenario) {
        if (lowerText.startsWith('tactic – lateral movement')) {
          currentScenario.tactic = text.replace('Tactic – Lateral Movement', '').trim();
        }

        if (lowerText.includes('description of the attack')) {
          const descriptionPart = text.split(/Description of the attack[\s:-]*/i)[1] || '';
          currentScenario.description = descriptionPart.trim();
          isInDescription = true;
        } else if (isInDescription) {
          const isEndHeader = endHeaders.some(header => lowerText.startsWith(header));
          if (isEndHeader) {
            isInDescription = false;
          } else {
            currentScenario.description += ' ' + text;
          }
        }
        else if (lowerText.startsWith('difficulty realizing the threat:')) {
          currentScenario.difficulty = text.replace('Difficulty realizing the threat:', '').trim();
        } else if (lowerText.startsWith('level of impact:')) {
          currentScenario.impact = text.replace('Level of impact:', '').trim();
        }

        if (lowerText.includes('attack flow')) {
          for (let j = i + 1; j < elements.length; j++) {
            const nextEl = elements[j];
            const img = nextEl.querySelector('img');
            if (img) {
              currentScenario.attackFlowImage = img.getAttribute('src');
              i = j;
              break;
            }
          }
        }
      }
    }

    if (currentScenario) scenarios.push(currentScenario);

    scenarios.forEach(scenario => {
      scenario.description = scenario.description.replace(/\s+/g, ' ').trim();
    });
    return scenarios;
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setIsLoading(true);
    setError('');
    setScenarios([]);

    try {
      const result = await mammoth.convertToHtml(
        { arrayBuffer: await file.arrayBuffer() },
        {
          includeEmbeddedStyleMap: true,
          convertImage: mammoth.images.imgElement(image => {
            return image.read('base64').then(content => ({
              src: `data:${image.contentType};base64,${content}`,
              contentType: image.contentType,
              content: content
            }));
          })
        }
      );

      const extractedScenarios = extractScenarios(result.value);
      const mappedScenarios = extractedScenarios.map(scenario => ({
        ...scenario,
        selectedSilverBullet: silverBullets.find(b => b.name === scenario.name) || null
      }));
      setScenarios(mappedScenarios);
    } catch (err) {
      setError('Error parsing document. Please check the file format.');
      console.error('DOCX parsing error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSilverBulletSelect = (scenarioId, silverBulletId) => {
    const bulletId = parseInt(silverBulletId, 10); // Parse to integer
    setScenarios(prev => prev.map(scenario => {
      if (scenario.id === scenarioId) {
        const selectedSilverBullet = silverBullets.find(b => b.id === bulletId);
        return {
          ...scenario,
          selectedSilverBullet,
          name: selectedSilverBullet ? selectedSilverBullet.name : scenario.name
        };
      }
      return scenario;
    }));
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const createScenariosFromDoc = async () => {
    setIsCreating(true);
    try {
      for (const scenario of scenarios) {
        if (!scenario.selectedSilverBullet) continue;

        const alreadyExists = existingScenarios.some(s => 
          s.silverBulletId === scenario.selectedSilverBullet.id
        );

        if (alreadyExists) {
          toast.info(`Skipping "${scenario.name}" - scenario already exists`);
          continue;
        }

        const scenarioData = {
          silverBulletId: scenario.selectedSilverBullet.id,
          silverBulletName: scenario.selectedSilverBullet.name,
          scenarioDifficulty: scenario.difficulty,
          scenarioImpact: scenario.impact,
          tactic: scenario.tactic,
          description: scenario.description,
          status: 'Pending'
        };

        await sdk.scenario.create(scenarioData);

        if (scenario.attackFlowImage) {
          const imageFile = dataURLtoFile(
            scenario.attackFlowImage,
            `${scenario.name}_lightchart.png`
          );
          await sdk.silverBullets.uploadFile(imageFile, scenario.selectedSilverBullet.id, 'lightchart');
        }

        toast.success(`Created scenario "${scenario.name}" successfully!`);
      }
    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.error && error.response.data.error.message;
      toast.error(errorMessage);
    } finally {
      setIsCreating(false);
    }
  };

  const allScenariosConnected = scenarios.length > 0 && 
    scenarios.every(s => s.selectedSilverBullet !== null);

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card className="shadow-sm border-0">
            <CardBody>
              <CardTitle tag="h4" className="text-center mb-4">
                DOCX Scenario Parser
              </CardTitle>

              <FormGroup>
                <Label for="docxUpload">Upload Report DOCX</Label>
                <Input
                  type="file"
                  id="docxUpload"
                  accept=".docx"
                  onChange={handleFileUpload}
                  disabled={isLoading}
                />
                {error && <div className="text-danger mt-2">{error}</div>}
              </FormGroup>

              {scenarios.length > 0 && (
                <div className="mt-4">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <Button
                      color="success"
                      onClick={createScenariosFromDoc}
                      disabled={isCreating || !allScenariosConnected}
                    >
                      {isCreating ? 'Creating...' : 'Create All Scenarios'}
                    </Button>
                    <div className="text-muted">
                      {allScenariosConnected ? 
                        'All scenarios connected ✓' : 
                        'Missing SilverBullet connections ✗'}
                    </div>
                  </div>

                  {scenarios.map((scenario) => {
                    const scenarioExists = existingScenarios.some(s => 
                      s.silverBulletId === (scenario.selectedSilverBullet && scenario.selectedSilverBullet.id)
                    );

                    return (
                      <Card key={scenario.id} className="mb-4">
                        <CardBody>
                          <div className={`mb-3 ${scenarioExists ? 'text-warning' : 
                            scenario.selectedSilverBullet ? 'text-success' : 'text-danger'}`}>
                            <strong>
                              {scenarioExists ? '⚠ Already exists' :
                               scenario.selectedSilverBullet ? '✓ Ready to create' : 
                               '✗ No SilverBullet selected'}
                            </strong>
                          </div>

                          {!scenario.selectedSilverBullet && (
                            <FormGroup>
                              <Label>Select SilverBullet for "{scenario.name}"</Label>
                              <Input
                                type="select"
                                value=""
                                onChange={(e) => handleSilverBulletSelect(scenario.id, e.target.value)}
                              >
                                <option value="">Select a SilverBullet...</option>
                                {silverBullets.map(bullet => (
                                  <option key={bullet.id} value={bullet.id}>
                                    {bullet.name}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          )}

                          <CardTitle tag="h5">
                            {scenario.selectedSilverBullet ? 
                              scenario.selectedSilverBullet.name : 
                              scenario.name}
                          </CardTitle>

                          <CardText>
                            <strong>Difficulty:</strong> {scenario.difficulty}<br />
                            <strong>Impact Level:</strong> {scenario.impact}<br />
                            <strong>Tactic:</strong> {scenario.tactic}<br />
                            <strong>Description:</strong> {scenario.description}
                          </CardText>

                          {scenario.attackFlowImage && (
                            <div className="mt-3">
                              <strong>Attack Flow:</strong>
                              <img
                                src={scenario.attackFlowImage}
                                alt="Attack flow diagram"
                                className="img-fluid mt-2"
                                style={{ maxHeight: '300px' }}
                              />
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    );
                  })}
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DocxParser;