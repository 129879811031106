import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  MenuItem,
  Typography,
  Grid,
  Paper,
  Autocomplete,
  DialogActions,
  Box,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Delete as DeleteIcon, Save as SaveIcon } from '@mui/icons-material';
import sdk from 'api-sdk';
import { toast } from 'react-toastify';

// Create a dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#90caf9' },
    secondary: { main: '#f44336' }, // Red color for delete actions
    background: { paper: '#121212', default: '#303030' },
  },
  typography: { h4: { color: '#ffffff' } },
});

const EditScenario = ({ scenario, onClose, onSave }) => {
  const [form, setForm] = useState({
    silverBulletId: '',
    silverBulletName: '',
    scenarioDifficulty: '',
    scenarioImpact: '',
    tactic: '',
    description: '',
    status: 'Pending',
  });

  const [silverBullets, setSilverBullets] = useState([]);
  const [selectedBullet, setSelectedBullet] = useState(null);
  const [errors, setErrors] = useState({});
  const [lightChartFile, setLightChartFile] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleting, setDeleting] = useState(false); // To handle deletion loading state

  useEffect(() => {
    if (scenario) {
      setForm(scenario);
      setSelectedBullet({
        id: scenario.silverBulletId,
        name: scenario.silverBulletName,
      });
    }
    fetchSilverBullets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario]);

  const fetchSilverBullets = async () => {
    try {
      const response = await sdk.silverBullets.get();
      setSilverBullets(response);
    } catch (error) {
      console.error('Error fetching SilverBullets:', error);
      toast.error('Failed to fetch SilverBullets.');
    }
  };

  const difficultyOptions = ['Low', 'Medium', 'High', 'נמוך', 'בינוני', 'גבוה'];
  const impactOptions = ['Low', 'Medium', 'High', 'נמוך', 'בינוני', 'גבוה'];

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleBulletChange = (event, newValue) => {
    setSelectedBullet(newValue);
    if (newValue) {
      setForm({
        ...form,
        silverBulletId: newValue.id,
        silverBulletName: newValue.name,
      });
    } else {
      setForm({
        ...form,
        silverBulletId: '',
        silverBulletName: '',
      });
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!form.silverBulletId) newErrors.silverBulletId = 'SilverBullet ID is required';
    if (!form.silverBulletName) newErrors.silverBulletName = 'SilverBullet name is required';
    if (!form.scenarioDifficulty) newErrors.scenarioDifficulty = 'Difficulty is required';
    if (!form.scenarioImpact) newErrors.scenarioImpact = 'Impact is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      toast.error('Please fix the errors in the form.');
      return;
    }

    try {
      await sdk.scenario.update(scenario.id, form);
      if (lightChartFile) {
        await sdk.silverBullets.uploadFile(lightChartFile, form.silverBulletId, 'lightchart');
      }
      toast.success('Scenario updated successfully!');
      onSave();
      onClose();
    } catch (error) {
      console.error('Error updating scenario:', error);
      toast.error('Failed to update scenario.');
    }
  };

  const handleDelete = async () => {
    setDeleting(true);
    try {
      await sdk.scenario.delete(scenario.id);
      toast.success('Scenario deleted successfully!');
      onSave();
      onClose();
    } catch (error) {
      console.error('Error deleting scenario:', error);
      toast.error('Failed to delete scenario.');
    } finally {
      setDeleting(false);
      setShowDeleteConfirm(false);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      {/* Main Edit Dialog */}
      <Dialog open={!!scenario} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle sx={{ color: 'white', backgroundColor: 'background.paper' }}>
          Edit Scenario
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: 'background.default' }}>
          <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                {/* SilverBullet Name (Autocomplete) */}
                <Grid item xs={12}>
                  <Autocomplete
                    fullWidth
                    options={silverBullets}
                    getOptionLabel={(option) => option.name || ''}
                    value={selectedBullet}
                    onChange={handleBulletChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="SilverBullet Name"
                        error={!!errors.silverBulletName}
                        helperText={errors.silverBulletName || ''}
                        required
                      />
                    )}
                  />
                </Grid>

                {/* SilverBullet ID */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="SilverBullet ID"
                    name="silverBulletId"
                    value={form.silverBulletId}
                    onChange={handleChange}
                    disabled
                    required
                  />
                </Grid>

                {/* Scenario Difficulty */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Scenario Difficulty"
                    name="scenarioDifficulty"
                    value={form.scenarioDifficulty}
                    onChange={handleChange}
                    error={!!errors.scenarioDifficulty}
                    helperText={errors.scenarioDifficulty || ''}
                    required
                  >
                    {difficultyOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* Scenario Impact */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    label="Scenario Impact"
                    name="scenarioImpact"
                    value={form.scenarioImpact}
                    onChange={handleChange}
                    error={!!errors.scenarioImpact}
                    helperText={errors.scenarioImpact || ''}
                    required
                  >
                    {impactOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {/* Tactic */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Tactic"
                    name="tactic"
                    value={form.tactic}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Description */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    value={form.description}
                    onChange={handleChange}
                    multiline
                    rows={4}
                  />
                </Grid>

                {/* Upload Light Mode Chart */}
                <Grid item xs={12}>
                  <Box>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="light-chart-upload"
                      type="file"
                      onChange={(e) => setLightChartFile(e.target.files[0])}
                    />
                    <label htmlFor="light-chart-upload">
                      <Button variant="contained" component="span">
                        Upload Light Mode Chart
                      </Button>
                      {lightChartFile && (
                        <Typography variant="body2" sx={{ mt: 1, color: 'white' }}>
                          {lightChartFile.name}
                        </Typography>
                      )}
                    </label>
                  </Box>
                </Grid>

                {/* Update and Delete Buttons */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {/* Update Scenario Button */}
                    <Grid item xs={12} sm={6}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                      >
                        Update Scenario
                      </Button>
                    </Grid>

                    {/* Delete Scenario Button */}
                    <Grid item xs={12} sm={6}>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() => setShowDeleteConfirm(true)}
                        disabled={deleting}
                      >
                        Delete Scenario
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ color: 'white', backgroundColor: 'background.paper' }}>
          Confirm Deletion
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: 'background.default' }}>
          <Typography>
            Are you sure you want to delete this scenario? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: 'background.default', padding: 2 }}>
          <Button onClick={() => setShowDeleteConfirm(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            color="secondary"
            variant="contained"
            disabled={deleting}
            startIcon={<DeleteIcon />}
          >
            {deleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default EditScenario;
