import React, { useState, useEffect } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import sdk from 'api-sdk';
import defenseRuleOptions from '../../../../assets/data/edrs.json';
import './runHistoryModal.css';

const RunHistoryModal = ({
    isOpen,
    toggle,
    bullet,
    onSaveRunHistory,
    runHistory,
}) => {
    const [selectedCompany, setSelectedCompany] = useState('');
    const [runDate, setRunDate] = useState('');
    const [notes, setNotes] = useState('');
    const [status, setStatus] = useState('');
    const [companies, setCompanies] = useState([]);
    const [runNumber, setRunNumber] = useState('');
    const [selectedDefenseRule, setSelectedDefenseRule] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (runHistory) {
            setSelectedCompany(runHistory.companyName || '');
            setRunDate(runHistory.runDate ? runHistory.runDate.split('T')[0] : '');
            setNotes(runHistory.notes || '');
            setStatus(runHistory.status || '');
            setSelectedDefenseRule(runHistory.defenceRules || '');
            setRunNumber(runHistory.runNumber || '');
        } else {
            // Reset fields when creating a new run history
            setSelectedCompany('');
            setRunDate('');
            setNotes('');
            setStatus('');
            setSelectedDefenseRule('');
            setRunNumber('');
            setErrors({});
        }
    }, [runHistory, isOpen]);

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const response = await sdk.companies.getAll();
                setCompanies(response);
            } catch (error) {
                console.error('Error fetching companies:', error);
                toast.error('Failed to fetch companies.');
            }
        };

        fetchCompanies();
    }, []);

    const validate = () => {
        const newErrors = {};

        if (!selectedCompany) newErrors.selectedCompany = 'Company is required.';
        if (!status) newErrors.status = 'Status is required.';
        if (!runDate) newErrors.runDate = 'Run date is required.';
        if (!runNumber) newErrors.runNumber = 'Run number is required.';
        if (!selectedDefenseRule)
            newErrors.selectedDefenseRule = 'Defense rule is required.';

        setErrors(newErrors);

        // Return true if no errors
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) {
            toast.error('Please fix the errors in the form.');
            return;
        }

        const updatedRunHistory = {
            bulletId: bullet.id,
            companyName: selectedCompany,
            runDate,
            notes,
            status,
            runNumber,
            defenceRules: selectedDefenseRule,
        };

        try {
            await onSaveRunHistory(
                updatedRunHistory,
                runHistory ? runHistory.id : null
            );
            toast.success(
                runHistory ? 'Run history updated successfully!' : 'Run history created successfully!'
            );
            toggle(); // Close the modal after successful save
        } catch (error) {
            console.error('Error saving run history:', error);
            toast.error('Failed to save run history.');
        }
    };

    const getStatusClass = (status) => {
        switch (status) {
            case 'Pass':
                return 'status-pass';
            case 'Fail':
                return 'status-fail';
            case 'No defense':
                return 'status-no-defense';
            case 'Detected':
                return 'status-detected';
            case 'No POC':
                return 'status-no-poc';
            case 'No Working':
                return 'status-no-working';
            case 'Declined':
                return 'status-declined';
            default:
                return 'status-select';
        }
    };

    const getColorByStatus = (status) => {
        switch (status) {
            case 'Pass':
                return 'green';
            case 'Fail':
                return 'red';
            case 'No defense':
                return 'orange';
            case 'Detected':
                return 'blue';
            case 'No POC':
                return 'purple';
            case 'No Working':
                return 'brown';
            case 'Declined':
                return 'darksalmon';
            default:
                return 'inherit';
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    {runHistory ? 'Edit Run History' : 'Create New Run'}
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit} noValidate>
                        <FormGroup>
                            <Label for="bulletName">Bullet Name</Label>
                            <Input
                                type="text"
                                name="bulletName"
                                id="bulletName"
                                value={bullet.name}
                                readOnly
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="category">Category</Label>
                            <Input
                                type="text"
                                name="category"
                                id="category"
                                value={bullet.attackVector}
                                readOnly
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="statusSelect">Status</Label>
                            <Input
                                type="select"
                                name="status"
                                id="statusSelect"
                                style={{ color: getColorByStatus(status) }}
                                className={getStatusClass(status) + (errors.status ? ' is-invalid' : '')}
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <option value="" className="status-select">
                                    Select Status
                                </option>
                                <option value="Pass" className="status-pass">
                                    Pass
                                </option>
                                <option value="Fail" className="status-fail">
                                    Fail
                                </option>
                                <option value="No defense" className="status-no-defense">
                                    No defense
                                </option>
                                <option value="Detected" className="status-detected">
                                    Detected
                                </option>
                                <option value="No POC" className="status-no-poc">
                                    No POC
                                </option>
                                <option value="No Working" className="status-no-working">
                                    No Working
                                </option>
                                <option value="Declined" className="status-declined">
                                    Declined
                                </option>
                            </Input>
                            {errors.status && (
                                <div className="invalid-feedback">{errors.status}</div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="runDate">Run Date</Label>
                            <Input
                                type="date"
                                name="runDate"
                                id="runDate"
                                value={runDate}
                                onChange={(e) => setRunDate(e.target.value)}
                                className={errors.runDate ? 'is-invalid' : ''}
                            />
                            {errors.runDate && (
                                <div className="invalid-feedback">{errors.runDate}</div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="runNumber">Run Number</Label>
                            <Input
                                type="number"
                                name="runNumber"
                                id="runNumber"
                                value={runNumber}
                                onChange={(e) => setRunNumber(e.target.value)}
                                className={errors.runNumber ? 'is-invalid' : ''}
                            />
                            {errors.runNumber && (
                                <div className="invalid-feedback">{errors.runNumber}</div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="defenseRuleSelect">Defense Rule</Label>
                            <Input
                                type="select"
                                name="defenseRule"
                                id="defenseRuleSelect"
                                value={selectedDefenseRule}
                                onChange={(e) => setSelectedDefenseRule(e.target.value)}
                                className={errors.selectedDefenseRule ? 'is-invalid' : ''}
                            >
                                <option value="">Select Defense Rule</option>
                                {defenseRuleOptions.map((rule) => (
                                    <option key={rule.propName} value={rule.name}>
                                        {rule.name}
                                    </option>
                                ))}
                            </Input>
                            {errors.selectedDefenseRule && (
                                <div className="invalid-feedback">
                                    {errors.selectedDefenseRule}
                                </div>
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="notes">Notes</Label>
                            <Input
                                type="textarea"
                                name="notes"
                                id="notes"
                                value={notes}
                                onChange={(e) => setNotes(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="companySelect">Company</Label>
                            <Input
                                type="select"
                                name="company"
                                id="companySelect"
                                value={selectedCompany}
                                onChange={(e) => setSelectedCompany(e.target.value)}
                                className={errors.selectedCompany ? 'is-invalid' : ''}
                            >
                                <option value="">Select a company</option>
                                {companies.map((company) => (
                                    <option key={company.companyID} value={company.companyName}>
                                        {company.companyName}
                                    </option>
                                ))}
                            </Input>
                            {errors.selectedCompany && (
                                <div className="invalid-feedback">
                                    {errors.selectedCompany}
                                </div>
                            )}
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>
                        {runHistory ? 'Update Run' : 'Create Run'}
                    </Button>{' '}
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
};

export default RunHistoryModal;
