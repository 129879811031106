import React from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import ChartImage from './ChartImage';
import './styles.css';

const ScenarioCard = ({ scenario, onEdit, isSelected, onSelect }) => {
  const handleEditClick = (event) => {
    event.stopPropagation();
    onEdit(scenario); // Call parent handler
  };

  return (
    <Card
      className={`scenario-card shadow-sm h-100 d-flex flex-column ${
        isSelected ? 'selected-card' : ''
      }`}
      style={{ cursor: 'pointer', position: 'relative' }}
      onClick={() => onSelect(scenario)}
    >
      <CardBody className="flex-grow-1 d-flex flex-column">
        <div className="d-flex justify-content-between align-items-start">
          <CardTitle tag="h5" className="scenario-card-title">
            {scenario.silverBulletName}
          </CardTitle>

          <FaEdit
            onClick={handleEditClick}
            className="edit-icon"
            style={{ cursor: 'pointer', color: '#007bff' }}
            size={20}
          />
        </div>

        {scenario.description && (
          <CardText className="scenario-card-text">{scenario.description}</CardText>
        )}

        {/* If you need a chart, e.g. from your backend */}
        <ChartImage silverBulletId={scenario.silverBulletId} />

        {scenario.scenarioDifficulty && (
          <CardText className="scenario-card-text">
            <strong>Difficulty:</strong> {scenario.scenarioDifficulty}
          </CardText>
        )}

        {scenario.scenarioImpact && (
          <CardText className="scenario-card-text">
            <strong>Impact:</strong> {scenario.scenarioImpact}
          </CardText>
        )}

        {scenario.tactic && (
          <CardText className="scenario-card-text">
            <strong>Tactic:</strong> {scenario.tactic}
          </CardText>
        )}
      </CardBody>
    </Card>
  );
};

export default withRouter(ScenarioCard);
