// sdk/cortex.js

import { request } from './client';
var model = 'eye-of-the-enemy/Cortex';
var cortex = {
  get: function get(filter) {
    return request('get', "/".concat(model), null, {
      filter: filter
    });
  },
  getById: function getById(id, filter) {
    return request('get', "/".concat(model, "/").concat(id), null, {
      filter: filter
    });
  },
  create: function create(data) {
    return request('post', "/".concat(model), data);
  },
  update: function update(id, data) {
    return request('patch', "/".concat(model, "/").concat(id), data);
  },
  delete: function _delete(id) {
    return request('delete', "/".concat(model, "/").concat(id));
  }
};
export default cortex;