var axios = require('axios');
var client = axios.create();
export function setBaseUrl(baseUrl) {
  client.defaults.baseURL = baseUrl;
}
export function getBaseUrl() {
  return client.defaults.baseURL;
}
export function setAuthToken(authToken) {
  client.defaults.headers.common['Authorization'] = authToken;
}
export function getOrganization() {
  return client.defaults.params.organizationId;
}
export function setOrganization(organizationId) {
  client.defaults.params = {
    organizationId: organizationId
  };
}
export function clearAuthData() {
  client.defaults.headers.common['Authorization'] = undefined;
  client.defaults.params = {};
}
export function request() {
  var method = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'get';
  var url = arguments.length > 1 ? arguments[1] : undefined;
  var data = arguments.length > 2 ? arguments[2] : undefined;
  var params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var options = arguments.length > 4 ? arguments[4] : undefined;
  Object.assign(params, client.defaults.params);
  var mergedOptions = Object.assign({
    method: method,
    url: url,
    data: data,
    params: params
  }, options);
  var requestPromise = client(mergedOptions);
  requestPromise.catch(function (err) {
    var error = err.response && err.response.data.error;
    if (error && error.message === 'Invalid Access Token') {
      window.location.reload();
    } else {
      return err;
    }
  });
  return requestPromise.then(function (res) {
    return res.data;
  });
}
export function requestDownload(url, downloadAttr, options) {
  return request('get', url, undefined, undefined, {
    responseType: 'blob'
  }).then(function (res) {
    var blobUrl = window.URL.createObjectURL(new Blob([res], options || {}));
    if (downloadAttr) {
      var anchor = document.createElement('a');
      anchor.href = blobUrl;
      anchor.setAttribute('download', downloadAttr);
      anchor.hidden = true;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
    return blobUrl;
  });
}